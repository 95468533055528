const PortalRoutes = [
  {
    path: "/portals",
    name: "portals",
    meta: { menu: "portals" },
    component: () =>
      import(/* webpackChunkName: "portals" */ "@/views/portals/Portals.vue"),
    children: [
      {
        path: "overview",
        name: "portals-overview",
        meta: { menu: "portals", breadcrumb: "overview" },
        component: () =>
          import(
            /* webpackChunkName: "portals-overview" */ "@/views/portals/views/PortalsOverview.vue"
          ),
      },
      {
        path: "browse",
        name: "portals-browse",
        meta: { menu: "portals", breadcrumb: "browse" },
        props: { mode: "BROWSE" },
        component: () =>
          import(
            /* webpackChunkName: "portals-browse" */ "@/views/portals/views/portals-browse-and-trash/PortalsBrowseAndTrash.vue"
          ),
      },
      {
        path: "trash",
        name: "portals-trash",
        meta: { menu: "portals", breadcrumb: "trash" },
        props: { mode: "TRASH" },
        component: () =>
          import(
            /* webpackChunkName: "portals-trash" */ "@/views/portals/views/portals-browse-and-trash/PortalsBrowseAndTrash.vue"
          ),
      },
    ],
  },

  {
    path: "/portals/builder/:id?",
    name: "portal-builder",
    meta: { menu: "portals" },
    props: (route) => ({ portalId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "portal-builder" */ "@/views/portals/views/portal-builder-wrapper/PortalBuilderWrapper.vue"
      ),
  },
  {
    path: "/portals/:tid/:id",
    name: "portals-signin",
    props: (route) => ({
      tenantId: route.params.tid,
      portalId: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "portal-sigin" */ "@/views/portals/views/PortalsSignIn.vue"
      ),
  },
  {
    path: "/portals/:tid/:id",
    name: "portals-access",
    props: (route) => ({
      tenantId: route.params.tid,
      portalId: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "portal-access" */ "@/views/portals/views/PortalsAccess.vue"
      ),
  },
  {
    path: "/portals/:tid/:id",
    name: "portals-access-custom",
    props: (route) => ({
      tenantId: route.params.tid,
      portalId: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "portal-accesss-custom" */ "@/views/portals/views/PortalsAccessCustom.vue"
      ),
  },
];

export default PortalRoutes;
