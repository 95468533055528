import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  install(Vue) {
    Vue.prototype.$day = {
      format(dateTime, withTime = true) {
        if (!dateTime) {
          return;
        }

        return withTime
          ? dayjs(dateTime).format("DD-MMM-YYYY hh:mm A")
          : dayjs(dateTime).format("DD-MMM-YYYY");
      },

      isDateBetween(date, minDate, maxDate) {
        const isValidDateRange = dayjs(minDate).isBefore(dayjs(maxDate));
        if (!isValidDateRange) {
          return false;
        }

        return dayjs(date).isBetween(minDate, maxDate, "day", "[]");
      },

      isDateGreater(date, minDate) {
        return dayjs(date).isSameOrAfter(dayjs(minDate));
      },

      isDateLesser(date, maxDate) {
        return dayjs(date).isSameOrBefore(dayjs(maxDate));
      },

      customFormat(format) {
        return dayjs().format(format);
      },

      newDate() {
        return dayjs().format("YYYY-MM-DD");
      },

      newTime() {
        return dayjs().format("hh:mm A");
      },

      newDateTime() {
        return dayjs().format("YYYY-MM-DD hh:mm A");
      },

      parseDate(dateString) {
        const isValid = dayjs(dateString).isValid();
        return isValid ? dayjs(dateString).format("YYYY-MM-DD") : "";
      },

      parseTime(timeString) {
        const isValid = dayjs(timeString, "hh:mm A", true).isValid();
        return isValid ? timeString : "";
      },

      parseDateTime(dateTimeString) {
        const isValid = dayjs(dateTimeString).isValid();
        return isValid
          ? dayjs(dateTimeString).format("YYYY-MM-DD hh:mm A")
          : "";
      },

      addDate(date, num) {
        return dayjs(date).add(num, "day").format("YYYY-MM-DD");
      },

      addMonth(date, num) {
        return dayjs(date).add(num, "month").format("YYYY-MM-DD");
      },

      addYear(date, num) {
        return dayjs(date).add(num, "year").format("YYYY-MM-DD");
      },

      subtractDate(date, num) {
        return dayjs(date).subtract(num, "day").format("YYYY-MM-DD");
      },
    };
  },
};
