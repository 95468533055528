import { render, staticRenderFns } from "./SingleSelectFieldWrapper.vue?vue&type=template&id=4dfcc933&scoped=true&"
import script from "./SingleSelectFieldWrapper.vue?vue&type=script&lang=js&"
export * from "./SingleSelectFieldWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dfcc933",
  null
  
)

export default component.exports