import { axiosCrypto } from "./axios";

export default {
  async getForm(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/form/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form";
    }

    return _response;
  },

  async getForms(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/form/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching forms";
    }

    return _response;
  },

  async getFormList(criteria = "", value = "") {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/form/list",
        JSON.stringify({ criteria, value })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching forms";
    }

    return _response;
  },

  async createForm(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post("/form", JSON.stringify(payload));

      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 406) {
        _response.error = "form with the given name already exists";
      } else {
        _response.error = "error creating form";
      }
    }

    return _response;
  },

  async updateForm(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/form/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 404) {
        _response.error = "form with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "form with the given name already exists";
      } else {
        _response.error = "error updating form";
      }
    }

    return _response;
  },

  async getFormHistory(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/form/history/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form history";
    }

    return _response;
  },

  async getFormEntries(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/${id}/entry/all`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form entries";
    }

    return _response;
  },

  async saveFormEntry(formId, formEntryId, payload) {
    const _response = {
      data: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/${formId}/entry/${formEntryId}`,
        JSON.stringify(payload)
      );

      const { status, data } = response;

      // edit
      if (formEntryId && status !== 202) {
        throw response;
      }

      // create
      if (!formEntryId && status !== 201) {
        throw response;
      }

      _response.data = JSON.parse(data);
    } catch (e) {
      console.error(e);
      if (e.response.status === 409) {
        _response.error = e.response;
      } else {
        _response.error = "error saving form entry";
      }
    }
    return _response;
  },

  async deleteFormEntry(formId, formEntryId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.delete(
        `/form/${formId}/entry/${formEntryId}`
      );

      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting form entry";
    }

    return _response;
  },

  async deleteMultipleFormEntry(formId, type, data) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/${formId}/deleteEntry/${type}`,
        JSON.stringify(data)
      );

      const { status } = response;

      if (status !== 200) {
        throw response;
      }

      // _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error deleting form entry";
    }

    return _response;
  },

  async restoreMultipleFormEntry(formId, payload) {
    const _response = {
      data: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/${formId}/restoreEntry`,
        JSON.stringify(payload)
      );

      const { status } = response;

      if (status !== 200) {
        throw response;
      }
    } catch (e) {
      console.error(e);
      _response.error = "error restore form entry";
    }

    return _response;
  },

  async getFormEntry(formId, formEntryId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/form/${formId}/entry/${formEntryId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form entry";
    }

    return _response;
  },

  async getFormAttachments(formId, entryId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/form/taskAttachmentList/${formId}/${entryId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching attachments";
    }

    return _response;
  },

  async getFormComments(formId, entryId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/form/taskComments/${formId}/${entryId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching comments";
    }

    return _response;
  },

  async insertFormComment(formId, entryId, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/taskComments/${formId}/${entryId}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error saving comments";
    }

    return _response;
  },

  async linkTaskWorkflowProcess(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/form/taskWorkflow",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error saving workflows form Submit";
    }

    return _response;
  },

  async getWorkflowTaskList(wId, fId, entryId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/taskWorkflowList/${wId}/${fId}/${entryId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching workflowformsList";
    }

    return _response;
  },

  async getFormById(formId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/form/${formId}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form";
    }

    return _response;
  },

  async sendInvite(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/portal/invite/${id}`,
        JSON.stringify(payload)
      );

      const { status, data } = response;
      if (status !== 200) {
        throw response;
      }
      _response.payload = data;
    } catch (e) {
      console.error(e);

      _response.error = "error sending invite";
    }
    return _response;
  },

  async linkTaskDocument(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/linkTaskItem`,
        JSON.stringify(payload)
      );

      const { status, data } = response;
      if (status !== 201) {
        throw response;
      }
      _response.payload = data;
    } catch (e) {
      console.error(e);

      _response.error = "error link document";
    }
    return _response;
  },

  async getDocumentTaskEntries(formId, repositoryId, itemId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(
        `/form/itemTaskList/${formId}/${repositoryId}/${itemId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching document task entry";
    }

    return _response;
  },

  async formHistory(workflowId, processId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        `/form/getModifiedFormHistory/${workflowId}/${processId}`
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching form history";
    }

    return _response;
  },
};
