import Vue from "vue";

import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import "@quasar/extras/mdi-v5/mdi-v5.css";
import "@quasar/extras/eva-icons/eva-icons.css";
import Quasar from 'quasar/src/vue-plugin.js';;

Vue.use(Quasar, {
  plugins: {},
  config: {},
  framework: { all: "auto" },
});
