import { axiosCrypto } from "./axios";

export default {
  async getUser(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/user/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user";
    }

    return _response;
  },

  async getUsers(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/user/all",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching users";
    }

    return _response;
  },

  async getUserList(criteria = "userType", value = "Normal") {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/user/list",
        JSON.stringify({ criteria, value })
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching users";
    }

    return _response;
  },

  async getUniqueColumnValues(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/user/uniqueColumnValues",
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching data";
    }

    return _response;
  },

  async createUser(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post("/user", JSON.stringify(payload));

      const { status, data } = response;

      if (status !== 201) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 406) {
        _response.error = "user with the given email already exists";
      } else {
        _response.error = "error creating user";
      }
    }

    return _response;
  },

  async updateUser(id, payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.put(
        `/user/${id}`,
        JSON.stringify(payload)
      );
      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);

      if (e.response?.status === 404) {
        _response.error = "user with the given id is not found";
      } else if (e.response?.status === 406) {
        _response.error = "user with the given email already exists";
      } else {
        _response.error = "error updating user";
      }
    }

    return _response;
  },

  async getUserHistory(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.get(`/user/history/${payload}`);
      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = JSON.parse(data);
    } catch (e) {
      console.error(e);
      _response.error = "error fetching user history";
    }

    return _response;
  },

  async uploadImage(payload) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.post(
        "/user/avatarBinary",
        JSON.stringify(payload)
      );

      const { status, data } = response;

      if (status !== 200) {
        throw response;
      }

      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error uploading image";
    }

    return _response;
  },

  async removeImage(tenantId, userId) {
    const _response = {
      payload: "",
      error: "",
    };

    try {
      const response = await axiosCrypto.delete(
        `/user/avatar/${tenantId}/${userId}`
      );

      const { status, data } = response;

      if (status !== 202) {
        throw response;
      }
      _response.payload = data;
    } catch (e) {
      console.error(e);
      _response.error = "error removing image";
    }

    return _response;
  },
};
