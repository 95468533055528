import { render, staticRenderFns } from "./SingleChoiceFieldWrapper.vue?vue&type=template&id=88e02d0e&scoped=true&"
import script from "./SingleChoiceFieldWrapper.vue?vue&type=script&lang=js&"
export * from "./SingleChoiceFieldWrapper.vue?vue&type=script&lang=js&"
import style0 from "./SingleChoiceFieldWrapper.vue?vue&type=style&index=0&id=88e02d0e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e02d0e",
  null
  
)

export default component.exports