<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <CounterField
      :value="value"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :is-clearable="false"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "CounterFieldWrapper",

  components: { ValidationProvider, CounterField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Boolean,
      default: false,
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validationRules() {
      const rules = { integer: true };

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const minimum = this.field.settings.validation.minimum;
      if (minimum) {
        rules.min_value = minimum;
      }

      const maximum = this.field.settings.validation.maximum;
      if (maximum) {
        rules.max_value = maximum;
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            }
          }
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter() {
      let filterBy = [];
      this.filterBy.forEach((item) => {
        filterBy.push({ ...item, value: item.value.toString() });
      });
      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.handleInput(options[0]);
        } else {
          this.handleInput("");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
