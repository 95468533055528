<template>
  <ValidationProvider v-slot="{ errors }" :rules="validationRules">
    <FileUploadField
      :value="value"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :is-multiple="allowMultipleFiles"
      :is-clearable="false"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FileUploadField from "@/components/common/form/file-upload-field/FileUploadField.vue";

export default {
  name: "FileUploadFieldWrapper",

  components: { ValidationProvider, FileUploadField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this._isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      if (this.allowedFileTypes.length) {
        rules.fileTypes = this.allowedFileTypes;
      }

      if (this.maxFileSize) {
        rules.maxFileSize = this.maxFileSize;
      }

      return rules;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    allowedFileTypes() {
      return this.field.settings.validation.allowedFileTypes;
    },

    maxFileSize() {
      return this.field.settings.validation.maxFileSize;
    },

    allowMultipleFiles() {
      return this.field.settings.specific.allowMultipleFiles;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
