<template>
  <ValidationProvider v-slot="{ errors }" :name="field.label" class="col-12">
    <TableField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :calculations="_calculations"
      :table-columns="tableColumns"
      :secondary-panels="secondaryPanels"
      :table-row-choose-type="tableRowChooseType"
      :table-rows-type="tableRowsType"
      :table-fixed-row-count="tableFixedRowCount"
      :table-fixed-row-labels="tableFixedRowLabels"
      :qr-value="qrValue"
      :form-settings="formSettings"
      :form-id="formId"
      :has-master-table-change="hasMasterTableChange"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TableField from "@/components/common/form/table-field/TableField.vue";
import { form } from "@/api/factory.js";
import { isEmpty } from "lodash-es";

export default {
  name: "TableFieldWrapper",

  components: { ValidationProvider, TableField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    rules: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },

    filter: {
      type: Boolean,
      default: false,
    },

    editControls: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hasMasterTableChange: false,
    };
  },

  computed: {
    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    tableColumns() {
      let tblColumns = [];
      if (this.tableRowChooseType) {
        tblColumns.push({
          id: this.$nano.id(),
          label: "",
          settings: {
            general: {},
            specific: {},
            validation: {},
          },
          size: "SMALL",
          type: "SELECTION",
        });
      }
      this.field.settings.specific.tableColumns.forEach((col) => {
        // if (this.editControls.length) {
        //   if (
        //     typeof this.editControls[0] === "object" ||
        //     typeof this.editControls[0] === "string"
        //   ) {
        //     let formEditControls = [];
        //     if (typeof this.editControls[0] === "object") {
        //       formEditControls = this.editControls;
        //     } else {
        //       this.editControls.forEach((edit) => {
        //         if (typeof edit === "string") {
        //           formEditControls.push(JSON.parse(edit));
        //         }
        //       });
        //     }
        //     let hasEmail = formEditControls.find(
        //       (item) => item.userId === this.$store.state.session.id
        //     );
        //     console.log(hasEmail, hasEmail.formFields);
        //     if (hasEmail && hasEmail.formFields.length) {
        //       // let formfields = [];
        //       hasEmail.formFields.forEach((form) => {
        //         let table = form.split("@");
        //         // console.log(table);
        //         if (table.length === 2) {
        //           console.log(table[1].trim(), col.id, "same");
        //           if (table[1].trim() !== col.id) {
        //             // return true;
        //             col.settings.general.visibility = "READ_ONLY";
        //             // formfields.push(table[0].trim());
        //           }
        //         }
        //       });
        //     }
        //   }
        // } else {
        tblColumns.push(col);
        // }
      });
      return tblColumns;
    },

    tableRowChooseType() {
      return this.field.settings.specific.tableRowChooseType;
    },

    tableRowsType() {
      return this.field.settings.specific.tableRowsType;
    },

    tableFixedRowCount() {
      return this.field.settings.specific.tableFixedRowCount;
    },

    tableFixedRowLabels() {
      return this.field.settings.specific.tableFixedRowLabels;
    },

    _calculations() {
      const _calculations = [];

      for (const rule of this.rules) {
        if (rule.isConditionalRule) {
          return;
        }

        const calculations = rule.calculations;

        for (const calculation of calculations) {
          if (calculation.fieldId === this.field.id) {
            _calculations.push(calculation);
          }
        }
      }

      return _calculations;
    },

    qrValue() {
      return this.field.settings.specific.qrValue;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (this.field.settings.specific.masterFormParentColumn) {
          this.$emit(
            "getParentColumnValue",
            this.field.settings.specific.masterFormParentColumn
          );
        }
      },
    },
  },

  created() {
    if (this.editControls.length) {
      if (
        typeof this.editControls[0] === "object" ||
        typeof this.editControls[0] === "string"
      ) {
        let formEditControls = [];
        if (typeof this.editControls[0] === "object") {
          formEditControls = this.editControls;
        } else {
          this.editControls.forEach((edit) => {
            if (typeof edit === "string") {
              formEditControls.push(edit);
            } else {
              formEditControls.push(JSON.parse(edit));
            }
          });
        }
        let hasEmail = formEditControls.find(
          (item) => item.userId === this.$store.state.session.id
        );
        if (hasEmail && hasEmail.formFields.length) {
          this.field.settings.specific.tableColumns.forEach((col) => {
            let fields = [];
            hasEmail.formFields.forEach((item) => {
              let table = item.split("@");
              if (fields.indexOf() === -1 && table.length === 2) {
                fields.push(table[1].trim());
              }
            });
            let find = fields.find((fie) => fie === col.id);
            if (!find) {
              col.settings.general.visibility = "READ_ONLY";
            }
          });
        }
      }
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    async getMasterEntriesFilter(field) {
      let columns = field.settings.specific.masterFormTableColumns;

      const { error, payload } = await form.getFormEntries(
        field.settings.specific.masterFormId,
        {
          mode: "BROWSE",
          sortBy: {
            criteria: "",
            order: "DESC",
          },
          groupBy: "",
          filterBy: [
            {
              id: this.$nano.id(),
              filters: field.filterBy,
              groupCondition: "",
            },
          ],
          itemsPerPage: 0,
          currentPage: 1,
        }
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        let rows = [];
        if (data[0].value.length) {
          data[0].value.forEach((control) => {
            let tableValue = {};
            for (let key in control) {
              let hasName = columns.find((item) => item.masterColumn === key);
              if (!isEmpty(hasName)) {
                this.$set(
                  tableValue,
                  [hasName.tableColumn],
                  control[hasName.masterColumn]
                );
              } else if (
                typeof control[key] === "string" &&
                control[key] &&
                this.$day.format(control[key]) === "Invalid Date"
              ) {
                if (control[key].charAt(0) === "[") {
                  let table = JSON.parse(control[key]);
                  table.forEach((col) => {
                    let tableValue1 = {};
                    for (let tableKey in col) {
                      let tableName = columns.find(
                        (item) => item.masterColumn === tableKey
                      );
                      if (!isEmpty(tableName)) {
                        this.$set(
                          tableValue1,
                          [tableName.tableColumn],
                          col[tableName.masterColumn]
                        );
                      }
                    }

                    if (!isEmpty(tableValue1)) {
                      rows.push(tableValue1);
                    }
                  });
                }
              }
            }
            if (!isEmpty(tableValue)) {
              rows.push(tableValue);
            }
          });

          this.hasMasterTableChange = !this.hasMasterTableChange;
          this.handleInput(rows);
        } else {
          this.hasMasterTableChange = !this.hasMasterTableChange;
          this.handleInput([]);
        }
      } else {
        this.hasMasterTableChange = !this.hasMasterTableChange;
        this.handleInput([]);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
