<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{ required: !_isReadonly ? _isMandatory : false }"
  >
    <SingleChoiceField
      :value="value"
      :is-mandatory="!_isReadonly ? _isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options.length ? options : []"
      :options-per-line="optionsPerLine"
      :is-clearable="false"
      :show-options-wrapper="false"
      class="single-choice"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "SingleChoiceFieldWrapper",

  components: { ValidationProvider, SingleChoiceField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "0",
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Boolean,
      default: false,
    },

    rowIndex: {
      type: Number,
      required: true,
    },

    filterBy: {
      type: Array,
      default: () => [],
    },

    formSettings: {
      type: Object,
      required: true,
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      readonlySettings: [],
      mandatorySettings: [],
    };
  },

  computed: {
    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    _isMandatory() {
      if (this.isMandatory) {
        return true;
      } else {
        return this.field.settings.validation.fieldRule === "REQUIRED";
      }
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    optionsPerLine() {
      return this.field.settings.specific.optionsPerLine;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filterBy) {
          if (this.filterBy.length) {
            if (this.field.settings.specific.masterFormId) {
              this.getMasterEntriesFilter();
            }
          }
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
    } else if (optionsType === "MASTER") {
      this.getMasterEntries(
        this.field.settings.specific.masterFormId,
        this.field.settings.specific.masterFormColumn
      );
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;

    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }

    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    if (this.mandatorySettings && this.mandatorySettings.length) {
      this.setMandatory(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);

      this.setReadOnly(value);
      this.setMandatory(value);

      if (this.parentControl) {
        this.$emit("filterBy", this.field, this.rowIndex);
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";
      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter() {
      let filterBy = [];
      this.filterBy.forEach((item) => {
        filterBy.push({ ...item, value: item.value.toString() });
      });

      try {
        const response = await axiosCrypto.post(
          `/form/${this.field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: this.field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            //this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          }
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
          this.handleInput("");
          this.$emit("updateOptions", this.field, this.rowIndex, this.options);
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setReadOnly(value, immediate) {
      // console.log(this.readonlySettings, value);
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let controls = this.readonlySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "readonlyControls",
              controls.controls,
              this.readonlySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          }
        }
      }
    },

    setMandatory(value, immediate) {
      // console.log(this.mandatorySettings, value);
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let controls = this.mandatorySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "mandatoryControls",
              controls.controls,
              this.mandatorySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id,
              this.rowIndex
            );
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-choice {
  // display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  padding-left: 6px;
  padding-top: 0px !important;
}
</style>
