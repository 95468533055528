<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <SingleChoiceField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options"
      :options-per-line="optionsPerLine"
      :highlight="highlight"
      :qr-value="qrValue"
      @input="handleInput"
      @verify="$emit('verify')"
      @scanQRCode="scanQRCode"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "SingleChoiceFieldWrapper",

  components: { ValidationProvider, SingleChoiceField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Boolean,
      default: false,
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    visibleControls: {
      type: Array,
      default: () => [],
    },

    hideControls: {
      type: Array,
      default: () => [],
    },

    updateValueFromSearch: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      options: [],
      mandatorySettings: [],
      readonlySettings: [],
    };
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    optionsPerLine() {
      return this.field.settings.specific.optionsPerLine;
    },

    qrValue() {
      return this.field.settings.specific.qrValue;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        // console.log(this.filter, this.field.filterBy);
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          } else if (this.field.settings.specific.repositoryId) {
            this.getRepositoryDataFilter(this.field);
          }
        }
      },
    },

    value: {
      immediate: true,
      deep: true,
      handler(oldVal, newVal) {
        if (this.value) {
          let optVal = this.options.find((row) => row.label === this.value);
          if (!optVal) {
            if (this.value) {
              this.options.push({
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              });
            }
          }
          if (this.parentControl && !newVal && oldVal) {
            this.$emit("filterBy", this.field);
          }
        } else {
          if (newVal && !oldVal) {
            this.handleInput(this.value);
          }
        }
      },
    },

    updateValueFromSearch: {
      // immediate: true,
      handler() {
        if (this.updateValueFromSearch === this.value) {
          this.handleInput(this.value);
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
    } else if (optionsType === "MASTER") {
      this.getMasterEntries(
        this.field.settings.specific.masterFormId,
        this.field.settings.specific.masterFormColumn
      );
    }

    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    // console.log(this.mandatorySettings);
    if (this.mandatorySettings && this.mandatorySettings.length) {
      // if (this.value) {
      this.setMandatory(this.value, "immediate");
      // }
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;

    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);

      this.setMandatory(value);

      this.setReadOnly(value);

      if (this.parentControl) {
        this.$emit("filterBy", this.field);
      }
      if (this.enableControls) {
        if (Object.keys(this.enableControls).length) {
          if (this.enableControls.controls.length) {
            let index = this.enableControls.controls.findIndex(
              (row) => row.id === this.field.id
            );
            if (index > -1) {
              let showControls = this.visibleControls;
              let enabled = [];
              this.enableControls.controls[index].parentControls.forEach(
                (control) => {
                  if (control.value === value) {
                    showControls.push(...control.childControls);
                    enabled.push(...control.childControls);
                  } else {
                    control.childControls.forEach((child) => {
                      if (
                        showControls.indexOf(child) > -1 &&
                        enabled.indexOf(child) === -1
                      ) {
                        this.hideControls.push(child);
                        showControls.splice(showControls.indexOf(child), 1);
                      }
                    });
                  }
                }
              );
              this.$emit("update:hideControls", this.hideControls);
              //this.$emit("updateVisibleControls", showControls);
            }
          }
        }
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
        let optVal = this.options.find((row) => row.label === this.value);
        if (!optVal) {
          if (this.value) {
            this.options.push({
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (options.length === 1) {
            //this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          }
          this.$emit("clearFilter");
        } else {
          if (this.value) {
            this.options = [
              {
                id: this.$nano.id(),
                label: this.value,
                value: this.value,
              },
            ];
          }
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setMandatory(value, immediate) {
      // console.log(this.mandatorySettings, value);
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let controls = this.mandatorySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "mandatoryControls",
              controls.controls,
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          }
        }
      }
    },

    setReadOnly(value, immediate) {
      // console.log(this.readonlySettings, value);
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let controls = this.readonlySettings.find(
            (control) => control.value.trim() === value.trim()
          );
          // console.log(controls, value);
          if (controls) {
            this.$emit(
              "readonlyControls",
              controls.controls,
              this.readonlySettings,
              immediate,
              this.field.id
            );
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id
            );
          }
        }
      }
    },

    scanQRCode() {
      this.$emit("scanQRCode", this.field);
    },
  },
};
</script>

<style lang="scss" scoped></style>
